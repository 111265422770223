import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import reportWebVitals from './reportWebVitals';

import NavigationBanner from '../../webhookmanager/src/components/NavigationBanner';
import Home from '../../webhookmanager/src/pages/home';
import Rollback from '../../webhookmanager/src/pages/rollback';
import Webhooks from '../../webhookmanager/src/pages/webhooks';
import Logout from '../../webhookmanager/src/pages/logout';
import Administration from "./pages/administration";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <NavigationBanner />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/rollback" element={<Rollback />} />
                <Route path="/webhooks" element={<Webhooks />} />
                <Route path="/administration" element={<Administration />} />
                <Route path="/logout" element={<Logout />} />
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
