import React from 'react';
import '../styles/spinnerLoader.css';
import spinnerImg from '../sp.svg'; // Importujeme SVG obrázek

const SpinnerLoader = () => {
    return (
        <div className="spinner-container">
            <img src={spinnerImg} alt="Loading..." className="spinner" />
        </div>
    );
}

export default SpinnerLoader;