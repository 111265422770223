import React, { useEffect, useState } from "react";
import axios from "axios";
import secrets from '../config.json';
import SpinnerLoader from "../components/SpinnerLoader";

const DISCORD_CLIENT_ID = secrets.DISCORD_CLIENT_ID;
const DISCORD_CLIENT_SECRET = secrets.DISCORD_CLIENT_SECRET;
const DISCORD_REDIRECT_URI = secrets.DISCORD_REDIRECT_URI;
const API = secrets.API;

function Rollback() {
    const [readyToRedirect, setReadyToRedirect] = useState(false);
    const UriParams = new URLSearchParams(window.location.search);
    const code = UriParams.get("code");
    console.log("LINE 13: ", code);

    function setCookie(cname, cvalue, exdays) {
        const d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(";");
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    const postDataToken = async () => {
        try {
            let response = await axios.post(
                "https://discord.com/api/oauth2/token",
                new URLSearchParams({
                    client_id: DISCORD_CLIENT_ID,
                    client_secret: DISCORD_CLIENT_SECRET,
                    code: code,
                    grant_type: "authorization_code",
                    redirect_uri: DISCORD_REDIRECT_URI,
                }),
                { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
            );

            console.log("Odpověď od Discordu:", response.data); // Zalogování úspěšné odpovědi

            setCookie("access_token", response.data.access_token, 7);
            console.log("RESPONSE TOKEN:", response.data.access_token);
            console.log("COOKIE TOKEN VALUE:", getCookie("access_token"));
        } catch (error) {
            console.error("Chyba při odesílání požadavku na Discord API:", error);
            if (error.response) {
                console.error("Odpověď Discord API:", error.response);
            }
        }
    };

    const getUserInfo = async () => {
        try {
            let response = await axios.get(
                "https://discord.com/api/v10/users/@me",
                { headers: { Authorization: `Bearer ${getCookie("access_token")}` } }
            );
            console.log("Odpověď od Discordu (userinfo):", response.data); // Zalogování úspěšné odpovědi

            setCookie("user_id", response.data.id, 7); // Uloží na 7 dní
            console.log("Cookie_user_id:", response.data.id);

            return response.data.id; // Vrátí ID uživatele
        } catch (error) {
            console.error("Chyba při odesílání požadavku na Discord API:", error);
            if (error.response) {
                console.error("Odpověď Discord API:", error.response);
            }
        }
    };

    const postDataToBackend = async (discord_id) => {
        try {
            let response = await axios.post(`${API}login/`, {
                params: {
                    discord_id: discord_id
                }
            });
            console.log("Odpověď od serveru (login):", response.data); // Zalogování úspěšné odpovědi
        } catch (error) {
            console.error("Chyba při odesílání požadavku na backend API:", error);
            if (error.response) {
                console.error("Odpověď backend API:", error.response);
            }
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            if (code) {
                await postDataToken();
                const userId = await getUserInfo();
                await postDataToBackend(userId);
                setReadyToRedirect(true);
            }
        };

        fetchData();
    }, [code]);

    useEffect(() => {
        if (readyToRedirect) {
            window.location.href = "/";
        }
    }, [readyToRedirect]);

    return (
        <div>
            <SpinnerLoader />
        </div>
    );
}

export default Rollback;
