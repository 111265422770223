import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import secrets from '../config.json';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { SketchPicker } from 'react-color';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faPlus } from '@fortawesome/free-solid-svg-icons';
import 'react-tooltip/dist/react-tooltip.css';
import '../styles/webhooks.css';

const DISCORD_CLIENT_ID = secrets.DISCORD_CLIENT_ID;
const DISCORD_CLIENT_SECRET = secrets.DISCORD_CLIENT_SECRET;
const DISCORD_REDIRECT_URI = secrets.DISCORD_REDIRECT_URI;
const API = secrets.API;

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function Webhooks() {
    const navigate = useNavigate();
    const [webhooks, setWebhooks] = useState([]);
    const [selectedWebhook, setSelectedWebhook] = useState(null);
    const [show, setShow] = useState(false);
    const [showNewWebhookModal, setShowNewWebhookModal] = useState(false);
    const [newWebhookName, setNewWebhookName] = useState('');
    const [newWebhookUrl, setNewWebhookUrl] = useState('');
    const [color, setColor] = useState('#E55333');

    const user = getCookie("user_id");

    useEffect(() => {
        if (!getCookie("access_token") || !getCookie("user_id")) {
            navigate('/');
        } else {
            async function fetchWebhooks() {
                try {
                    const response = await fetch(`${API}webhook/get/`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ user: user })
                    });
                    const data = await response.json();
                    setWebhooks(data);
                } catch (error) {
                    console.error("Error fetching webhooks:", error);
                }
            }

            fetchWebhooks();
        }
    }, [navigate, user]);

    const handleShow = (webhook) => {
        setSelectedWebhook(webhook);
        setColor(`#${webhook.color}`);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedWebhook(null);
    };

    const handleUpdate = async () => {
        const updatedWebhook = {
            id: String(selectedWebhook.id),
            name: String(selectedWebhook.name),
            img: String(selectedWebhook.icon),
            owner: String(selectedWebhook.owner),
            color: String(color.slice(1)),
            webhook_name: String(selectedWebhook.webhook_name),
            webhook_body: String(selectedWebhook.webhook_body),
            webhook_footer: String(selectedWebhook.webhook_footer),
        };

        try {
            const response = await fetch(`${API}webhook/update/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(updatedWebhook)
            });

            if (response.ok) {
                setWebhooks((prevWebhooks) => prevWebhooks.map(wh => wh.id === updatedWebhook.id ? updatedWebhook : wh));
                handleClose();
                window.location.reload();
            }
        } catch (error) {
            console.error("Error updating webhook:", error);
        }
    };

    const handleDelete = async () => {
        const deletedWebhook = {
            id: String(selectedWebhook.id),
        };

        try {
            const response = await fetch(`${API}webhook/delete/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(deletedWebhook)
            });

            if (response.ok) {
                setWebhooks((prevWebhooks) => prevWebhooks.filter(wh => wh.id !== deletedWebhook.id));
                handleClose();
                window.location.reload();
            }
        } catch (error) {
            console.error("Error deleting webhook:", error);
        }
    };

    const handleSaveAndSend = async () => {
        await handleUpdate();

        const data = {
            id: selectedWebhook.id,
            user: user
        };

        try {
            const response = await fetch(`${API}webhook/send/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                console.log("Webhook sent successfully");
                window.location.reload();
            }
        } catch (error) {
            console.error("Error sending webhook:", error);
        }
    };

    const handleNewWebhookModalClose = () => setShowNewWebhookModal(false);
    const handleNewWebhookModalShow = () => setShowNewWebhookModal(true);

    const handleCreateNewWebhook = async () => {
        const newWebhook = {
            name: newWebhookName,
            owner: user,
            webhook_url: newWebhookUrl
        };

        try {
            const response = await fetch(`${API}webhook/create/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newWebhook)
            });

            if (response.ok) {
                const createdWebhook = await response.json();
                setWebhooks([...webhooks, createdWebhook]);
                handleNewWebhookModalClose();
                window.location.reload();
            }
        } catch (error) {
            console.error("Error creating new webhook:", error);
        }
    };

    return (
        <div className="py-5 px-5">
            <Table striped bordered hover size="lg">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Název webhooku</th>
                    <th>
                        <Button variant="outline-success" onClick={handleNewWebhookModalShow}>
                            <FontAwesomeIcon icon={faPlus} />
                        </Button>
                    </th>
                </tr>
                </thead>
                <tbody>
                {webhooks.length > 0 ? (
                    webhooks.map((webhook) => (
                        <tr key={webhook.id}>
                            <td>{webhook.id}</td>
                            <td>{webhook.name}</td>
                            <td>
                                <Button variant="outline-dark" onClick={() => handleShow(webhook)}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                </Button>
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan="3">Žádné webhooky nebyly nalezeny.</td>
                    </tr>
                )}
                </tbody>
            </Table>


            <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Webhook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedWebhook && (
                        <div>
                            <div>
                                <strong>ID:</strong> {selectedWebhook.id}
                            </div>
                            <div className="mb-3">
                                <strong>Name:</strong>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={selectedWebhook.name}
                                    onChange={(e) => setSelectedWebhook({ ...selectedWebhook, name: e.target.value })}
                                />
                            </div>
                            <div className="grid-container">
                                <div className="grid-left">
                                    <a href={selectedWebhook.icon} target="_blank" rel="noopener noreferrer">
                                        <img src={selectedWebhook.icon} alt="icon"
                                             style={{width: '100pt', height: '100pt'}}/>
                                    </a>
                                    <input
                                        type="text"
                                        className="form-control mt-2"
                                        value={selectedWebhook.icon}
                                        onChange={(e) => setSelectedWebhook({ ...selectedWebhook, icon: e.target.value })}
                                    />
                                    <input
                                        type="text"
                                        className="form-control mt-2"
                                        value={selectedWebhook.owner}
                                        onChange={(e) => setSelectedWebhook({ ...selectedWebhook, owner: e.target.value })}
                                    />
                                </div>
                                <div className="grid-right">
                                    <SketchPicker
                                        color={color}
                                        onChangeComplete={(color) => setColor(color.hex)}
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <strong>Webhook Name:</strong>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={selectedWebhook.webhook_name}
                                    onChange={(e) => setSelectedWebhook({ ...selectedWebhook, webhook_name: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <strong>Webhook Body:</strong>
                                <textarea
                                    className="form-control"
                                    rows="10"
                                    value={selectedWebhook.webhook_body}
                                    onChange={(e) => setSelectedWebhook({ ...selectedWebhook, webhook_body: e.target.value })}
                                />
                            </div>
                            <div className="mb-3">
                                <strong>Webhook Footer:</strong>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={selectedWebhook.webhook_footer}
                                    onChange={(e) => setSelectedWebhook({ ...selectedWebhook, webhook_footer: e.target.value })}
                                />
                                <span data-tip="Uvnitř webhook footer je možné používat proměnná data pomocí složených závorek: {date} - datum, {time} - čas, {date_time} - datum a čas">
                                    <i className="fas fa-info-circle"></i>
                                </span>
                                <Tooltip />
                            </div>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleDelete}>
                        Smazat
                    </Button>
                    <Button variant="outline-primary" onClick={handleUpdate}>
                        Uložit a zavřít
                    </Button>
                    <Button variant="outline-success" onClick={handleSaveAndSend}>
                        Uložit a odeslat
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showNewWebhookModal} onHide={handleNewWebhookModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Přidat nový webhook</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-3">
                        <label><strong>Název:</strong></label>
                        <input
                            type="text"
                            className="form-control"
                            value={newWebhookName}
                            onChange={(e) => setNewWebhookName(e.target.value)}
                        />
                    </div>
                    <div className="mb-3">
                        <label><strong>Webhook URL:</strong></label>
                        <input
                            type="text"
                            className="form-control"
                            value={newWebhookUrl}
                            onChange={(e) => setNewWebhookUrl(e.target.value)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-danger" onClick={handleNewWebhookModalClose}>
                        Zavřít
                    </Button>
                    <Button variant="outline-success" onClick={handleCreateNewWebhook}>
                        Vytvořit
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Webhooks;
