import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../styles/navigationBanner.css';

function NavigationBanner() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isTrnass, setIsTrnass] = useState(false);

    useEffect(() => {
        const cookies = document.cookie.split(';').reduce((acc, cookie) => {
            const [key, value] = cookie.trim().split('=');
            acc[key] = value;
            return acc;
        }, {});

        if (cookies['access_token'] && cookies['user_id']) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        const cookies = document.cookie.split(';').reduce((acc, cookie) => {
            const [key, value] = cookie.trim().split('=');
            acc[key] = value;
            return acc;
        }, {});

        if (cookies['user_id'] === "240787388945661952") {
            setIsTrnass(true);
        }
    }, []);

    return (
        <Navbar collapseOnSelect expand="lg" className="custom-navbar">
            <Container>
                <Navbar.Brand>WebhookManager</Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/">Domů</Nav.Link>
                        <Nav.Link href="/webhooks">Webhooky</Nav.Link>
                        {isTrnass && (
                            <Nav.Link href="/administration">Administrace</Nav.Link>
                        )}
                    </Nav>
                    <Nav>
                        {isLoggedIn ? (
                            <Nav.Link href="/logout">Odhlášení</Nav.Link>
                        ) : (
                            <Nav.Link href="https://discord.com/oauth2/authorize?client_id=1249575252132888616&response_type=code&redirect_uri=https%3A%2F%2Fwebhook-manager.trnass.cz%2Frollback%2F&scope=identify">Přihlášení</Nav.Link>
                        )}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default NavigationBanner;
